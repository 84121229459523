import { Route } from "@angular/router";
import { FullPageLayoutComponent } from "@e-tenant-hub/client-platform/shell/ui/layouts/full-page-layout";
import { Roles, authInGuard, rolesInGuard } from "@e-tenant-hub/shared/auth";
import { SigninCallbackComponent, SignoutCallbackComponent } from "./components";

export const appRoutes: Route[] = [
	{
		path: "",
		pathMatch: "full",
		redirectTo: "home",
	},
	{
		path: "signin-callback",
		component: SigninCallbackComponent,
		data: {
			title: "Vous êtes connecté(e) à votre espace",
			description: "Vous êtes connecté(e) à votre espace",
		},
	},
	{
		path: "signout-callback",
		component: SignoutCallbackComponent,
		data: {
			title: "Vous avez été déconnecté(e) de votre espace",
			description: "Vous avez été déconnecté(e) de votre espace",
		},
	},
	{
		path: "",
		loadChildren: () =>
			import("@e-tenant-hub/client-platform/shell/ui/layouts/main-layout").then((m) => m.MainLayoutModule),
	},
	{
		path: "",
		component: FullPageLayoutComponent,
		children: [
			{
				path: "choose-account",
				canActivate: [authInGuard, rolesInGuard],
				data: { roles: [Roles.admin] },
				loadChildren: () =>
					import("@e-tenant-hub/client-platform/choose-account/feature").then((m) => m.ChooseAccountModule),
			},
			{
				path: "cookies-policy",
				loadChildren: () =>
					import("@e-tenant-hub/client-platform/cookies-policy/feature").then((m) => m.CookiesPolicyModule),
			},
			{
				path: "full-page-integration",
				canActivate: [authInGuard],
				loadChildren: () =>
					import("@e-tenant-hub/client-platform/full-page-integration/feature").then(
						(m) => m.FullPageIntegrationModule
					),
			},
			{
				path: "legal-notice",
				loadChildren: () =>
					import("@e-tenant-hub/client-platform/legal-notice/feature").then((m) => m.LegalNoticeModule),
			},
			// Errors
			{
				path: "forbidden",
				loadChildren: () =>
					import("@e-tenant-hub/client-platform/errors/forbidden/feature").then((m) => m.ForbiddenModule),
			},
			{
				path: "unauthorized",
				loadChildren: () =>
					import("@e-tenant-hub/client-platform/errors/unauthorized/feature").then(
						(m) => m.UnauthorizedModule
					),
			},
		],
	},
	{ path: "**", redirectTo: "/" },
];
