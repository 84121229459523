import { ApplicationRef, Injectable, inject } from "@angular/core";
import { SwUpdate, VersionEvent } from "@angular/service-worker";
import { Subject, concat, filter, first, interval, startWith, switchMap } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class PwaService {
	private readonly appRef = inject(ApplicationRef);
	private readonly updates = inject(SwUpdate);

	private _checkUpdatesSubject = new Subject<string>();
	private _appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
	private _everySixHours$ = interval(6 * 60 * 60 * 1000);
	private _everySixHoursOnceAppIsStable$ = concat(this._appIsStable$, this._everySixHours$);

	onNewVersionReady$ = this._checkUpdatesSubject.pipe(
		startWith("on-init"),
		filter(() => this.updates.isEnabled),
		switchMap(() => this.updates.versionUpdates),
		filter((e) => this.filterOnNewVersionReady(e))
	);

	onUnrecoverableVersion$ = this._checkUpdatesSubject.pipe(
		startWith("on-init"),
		filter(() => this.updates.isEnabled),
		switchMap(() => this.updates.unrecoverable)
	);

	registerCheckUpdates(): void {
		this._everySixHoursOnceAppIsStable$.subscribe(async () => {
			try {
				if (!this.updates.isEnabled) return;

				const updateFound = await this.updates.checkForUpdate();
				console.log(updateFound ? "A new version is available." : "Already on the latest version.");
			} catch (err) {
				console.error("Failed to check for updates:", err);
			}
		});
	}

	private filterOnNewVersionReady(versionEvent: VersionEvent): boolean {
		switch (versionEvent.type) {
			case "VERSION_DETECTED":
				console.log(`Downloading new app version: ${versionEvent.version.hash}`);
				return false;
			case "VERSION_READY":
				console.log(`Current app version: ${versionEvent.currentVersion.hash}`);
				console.log(`New app version ready for use: ${versionEvent.latestVersion.hash}`);
				return true;
			case "VERSION_INSTALLATION_FAILED":
				console.log(`Failed to install app version '${versionEvent.version.hash}': ${versionEvent.error}`);
				return false;
		}

		return false;
	}
}
