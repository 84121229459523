import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonBackgroundModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-background";
import { HeaderModule } from "@e-tenant-hub/client-platform/shell/ui/header";
import { FullPageLayoutComponent } from "./full-page-layout.component";

@NgModule({
	declarations: [FullPageLayoutComponent],
	imports: [CommonModule, HeaderModule, RouterModule, CommonBackgroundModule],
	exports: [FullPageLayoutComponent],
})
export class FullPageLayoutModule {}
