import { ChangeDetectionStrategy, Component, OnInit, inject } from "@angular/core";
import { AuthService } from "@e-tenant-hub/shared/auth";
import { CacheStorageService } from "@e-tenant-hub/shared/utils";

@Component({
	selector: "cp-signout-callback",
	templateUrl: "./signout-callback.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignoutCallbackComponent implements OnInit {
	private readonly authService = inject(AuthService);
	private readonly cacheStorageService = inject(CacheStorageService);

	ngOnInit(): void {
		this.cacheStorageService.clearSessionStorage();
		this.authService.login();
	}
}
