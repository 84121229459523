import { ChangeDetectionStrategy, Component, OnInit, inject } from "@angular/core";
import { NavigationExtras, Params, Router } from "@angular/router";
import { AuthService } from "@e-tenant-hub/shared/auth";
import { authSuccessEvent } from "@e-tenant-hub/shared/utils";

@Component({
	selector: "cp-signin-callback",
	templateUrl: "./signin-callback.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninCallbackComponent implements OnInit {
	private readonly authService = inject(AuthService);
	private readonly router = inject(Router);

	private rootUrl = "/";

	ngOnInit(): void {
		const uri = this.getRedirectUri(this.authService.stateUrl ?? this.rootUrl);
		const extras = this.buildExtras(uri);

		this.router.navigate([uri], extras);

		try {
			authSuccessEvent();
		} catch (error: unknown) {
			console.log("An error occurred while creating an google analytics event ");
		}
	}

	private getRedirectUri(uri: string): string {
		const isPreventRedirect = this.shouldPreventRedirect(uri);
		if (!isPreventRedirect) {
			if (this.authService.user.hasAdminRole) return "/choose-account";

			if (this.authService.user?.roles) return uri;
		}
		return this.rootUrl;
	}

	private shouldPreventRedirect(uri: string): boolean {
		const redirectUrls = [this.rootUrl, "/signin-callback", "/signout-callback"];
		return redirectUrls.includes(uri);
	}

	private buildExtras(uri: string): NavigationExtras | undefined {
		const queryParams: Params = {};

		if (!uri.includes("?")) return undefined;

		const parts = uri.split("?");

		uri = parts[0];
		const params = parts[1];

		const searchParams = new URLSearchParams(params);
		searchParams.forEach((value, key) => {
			queryParams[key] = value;
		});

		return {
			queryParams: queryParams,
		};
	}
}
